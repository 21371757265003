import React, { useEffect, useMemo, useState } from "react";
import { useStyles } from "./OverallIntro.style";
import Typography from "../../../common/Typography/Typography";
import Link from "../../../common/Link/Link";
import ArrowForwardIosIcon from "../../../common/Icons/ArrowForwardIos";
import TownPicker from "../../TownPicker/TownPicker";
import labels from "../../../config/labels";
import Location from "../../../common/Icons/Location";
import {
  getTownsAsItems,
  loadTownsWithClusters,
} from "../../../services/townService";
import withConfig from "../../../utils/withConfig";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import values from "../../../common/Theme/themes/breakpoints/values";

const OverallIntro = () => {
  const classes = useStyles();
  const [townsWithClusters, setTownsWithClusters] = useState([]);
  const [inputLongEnough, setInputLongEnough] = useState(false);
  const isMediumScreen = screenWidthIsLowerThan(values.lg);
  const isMobile = screenWidthIsLowerThan(values.md);
  const typographyLevel = useMemo(() => {
    if (isMobile) {
      return {
        header: "paragraph_2",
        link: "h3_sub_header_2",
      };
    }
    if (isMediumScreen) {
      return {
        header: "h4",
        link: "h3_sub_header_2",
      };
    }
    return {
      header: "h3",
      link: "h2_header_2",
    };
  }, [isMobile, isMediumScreen]);

  const fetchTownsWithClusters = async () => {
    const response = await loadTownsWithClusters();
    if (response?.status === 200) {
      setTownsWithClusters(response?.data?.towns_with_clusters);
    }
  };

  useEffect(() => {
    if (townsWithClusters.length === 0) {
      fetchTownsWithClusters();
    }
  }, [townsWithClusters]);

  const handleUserInput = (e) => {
    if (e?.target?.value?.length >= 3) {
      setInputLongEnough(true);
    } else {
      setInputLongEnough(false);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.left_section}>
        <Typography level={typographyLevel?.header} color="tapintoOrange" bold>
          {labels.ORIGINAL_LOCAL_NEWS_YOU_CAN_TRUST}
        </Typography>

        <Typography level="h3" bold className={classes.find_your_town}>
          {labels.FIND_YOUR_TOWN}
        </Typography>

        <Typography
          level={typographyLevel?.header}
          className={classes.town_name_or_zip}
        >
          {labels.ENTER_YOUR_TOWNS_NAME_OR_ZIP_CODE}
        </Typography>

        <TownPicker
          containerClassName={classes.town_picker_container}
          isMultipleWithTags={false}
          onComboboxInputChange={handleUserInput}
          townsAsItems={
            inputLongEnough && townsWithClusters.length > 0
              ? getTownsAsItems(townsWithClusters)
              : []
          }
          isTownPickerMobile={false}
          placeholder={labels.YOUR_TOWN_OR_ZIP_CODE}
          startIcon={
            <Location fillColor="black" className={classes.location_icon} />
          }
          comboBoxClasses={{
            focused: classes.town_picker_focused,
            inputFocused: classes.town_picker_input_focused,
            paper: classes.town_picker_paper,
            option: classes.town_picker_option,
          }}
          withNoTownOption={inputLongEnough}
        />

        <Link
          level={typographyLevel?.link}
          url={withConfig("SITES_PAGE")}
          children={
            <>
              {labels.VIEW_LIST_OF_SITES}
              <ArrowForwardIosIcon
                fontSize="small"
                classes={{ root: classes.arrow_icon }}
              />
            </>
          }
        />
      </div>

      <div className={classes.background_container}>
        <img
          fetchpriority="high"
          src={withConfig("OVERALL_BACKGROUND")}
          alt="tap-city-background"
        />
      </div>
    </div>
  );
};

export default OverallIntro;
