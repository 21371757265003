import ReactOnRails from "react-on-rails";
import MastheadLayout from "../src/components/Masterhead/Layouts/Layouts";
import { initializeGA } from "../src/utils/googleAnalytics";
import Ad from "../src/components/Ad/Ad";
import VideoAd from "../src/components/Ad/VideoAd";
import ExitIntentPopup from "../src/components/ExitIntentPopup/ExitIntentPopup";
import Footer from "../src/components/Footer/Footer";
import OverallHomepage from "../src/pages/OverallHomepage/OverallHomepage";
import TownsIndex from "../src/pages/TownsIndex/TownsIndex";
import OverallSubscription from "../src/pages/SignUp/Overall/OverallSubscription";

initializeGA();

ReactOnRails.register({
  Ad,
  VideoAd,
  ExitIntentPopup,
  OverallHomepage,
  Footer,
  TownsIndex,
  OverallSubscription,
  MastheadLayout
});
