const invoiceLabels = {
  TITLE: {
    INVOICE: "Entry",
    PRE_ENTRIES: "Pre-Entries",
    PREVIEW: "Preview",
  },
  MESSAGES: {
    NO_INVOICES: "No entries generated yet",
    DELETE_CONFIRMATION_MESSAGE:
      "Are you sure you want to delete the selected entries?",
  },
  INVOICE_PAYMENT_MODAL: {
    INVOICE_NUMBER: "Entry #",
    PAYMENT_TYPE: "Payment type",
    CHECK_NUMBER: "Check #",
    CREDIT_CARD_NUMBER: "Credit Card #",
    PAID_AMOUNT: "Paid Amount",
    SENT_DATE: "Sent Date",
    PAID_DATE: "Paid Date",
    STATUS: "Status",
    CC_PROCESSING_FEE: "CC Processing Fee",
  },
  INVOICE_LABELS: {
    SELECTED: "Selected",
    RECEIVE_MULTIPLE_PAYMENTS: "Receive multiple payments",
  },
};

export default invoiceLabels;
